export default {

    computed : {
        TIME_SELECT() {
            return this.GET_TIME_SELECT();
        },
        TIME_SELECT_10() {
            return this.GET_TIME_SELECT(10);
        },
        TIME_SELECT_15() {
            return this.GET_TIME_SELECT(15);
        },
        TIME_SELECT_20() {
            return this.GET_TIME_SELECT(20);
        },
        TIME_SELECT_60() {
            return this.GET_TIME_SELECT(60);
        },

        TIME_SELECT_NOW() {
            return this.GET_TIME_SELECT(30, true);
        },
        TIME_SELECT_NOW_10() {
            return this.GET_TIME_SELECT(10, true);
        },
        TIME_SELECT_NOW_15() {
            return this.GET_TIME_SELECT(15, true);
        },
        TIME_SELECT_NOW_20() {
            return this.GET_TIME_SELECT(20, true);
        },
        TIME_SELECT_NOW_60() {
            return this.GET_TIME_SELECT(60, true);
        }
    },
    methods : {
        MONTH_DAY(time , full = true) {
            if (!time) { return '' }
            let timeData = time.split(' ')
            let month = this.$t(timeData[0] + (full ? '' : '_short') )
            let day = full ? timeData[1].replace('0','') : full
            if (timeData && timeData.length > 1 ) {
                return month  + ", " + day
            }
            return time;
        },

        formatTime(inputTime) {

            var time = new Date(inputTime)
                time = `${time.getDate()}.${time.getMonth()} - ${time.getHours()}:${time.getMinutes()}`;
            return time;

        },
        TIME_YMD(inputTime) {
            if (!inputTime) { return '' }
            const time = new Date(inputTime*1000)
            let month = time.getMonth() + 1
            month = month < 10 ? `0${month}` : month
            let date = time.getDate()
            date = date < 10 ? `0${date}` : date
            return `${time.getFullYear()}.${month}.${date}`
        },
        PARSE_TIME_STAMP(inputTime , today = false , noTime = false ) {

            var time = new Date(inputTime*1000)

            if ( isNaN(time) ) {
                return ''
            }

            let year = !this.isThisYear(time) ? `.${time.getFullYear().toString().slice(-2)}` : '';
            let month = time.getMonth()  < 10 ? ('0' + (time.getMonth() + 1)) : (time.getMonth() + 1);
            let date = time.getDate()  < 10 ? ('0' +time.getDate() ) : time.getDate();
            let hours = time.getHours()  < 10 ? ('0' +time.getHours() ) : time.getHours();
            let minutes = time.getMinutes()  < 10 ? ('0' +time.getMinutes() ) : time.getMinutes();

            if ( today && this.isToday(time) ) {
                return `${hours}:${minutes}`;
            }
            if ( !noTime ) {
                return `${date}.${month}${year} - ${hours}:${minutes}`;
            } else {
                return `${date}.${month}`;
            }


        },
        PARSE_DATE( inputTime , isDate = false , timeStamp = false , displayYear = false , displayTime = false , today = false ) {

            if ( timeStamp ) {
                inputTime = inputTime * 1000
            }
            var time = !isDate ? new Date(inputTime) : inputTime

            if ( isNaN(time) ) {
                return ''
            }

            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let date = time.getDate()  < 10 ? ('0' +time.getDate() ) : time.getDate();
            let hours = time.getHours()  < 10 ? ('0' +time.getHours() ) : time.getHours();
            let minutes = time.getMinutes()  < 10 ? ('0' +time.getMinutes() ) : time.getMinutes();

            let result =  date + ' ' + this.$t(`Month_${month}`) + (!this.isThisYear(time) || displayYear ?  `, ${year}` : '')

            if ( today && this.isToday(time) ) {
                return `${hours}:${minutes}`;
            }

            if ( displayTime ) {
                result = `${result}, ${hours}:${minutes}`
            }
            return result
        },
        MONTH_DAY_TIME(timestamp , time = true , today = false) {
            return this.PARSE_DATE( timestamp , false, true , false , time , today )
        },

        isToday(date) {
            const today = new Date()
            return today.getDate() === date.getDate() &&
                today.getMonth() === date.getMonth() &&
                today.getFullYear() === date.getFullYear()
        },
        isThisYear(date) {
            const today = new Date()
            return today.getFullYear() === date.getFullYear()
        },

        GET_TIME_SELECT(step = 30, fromNow = false , fromNowHourCorrection = 0) {


            const validSteps = [5, 10, 15, 20, 30, 60];

            step = validSteps.reduce((prev, curr) =>
                Math.abs(curr - step) < Math.abs(prev - step) ? curr : prev
            );

            let steps = 60/step

            let items = [];
            let startHour = 0;
            let startMinute = 0;

            if ( fromNow) {
                startMinute = (new Date()).getMinutes()
                startHour = (new Date()).getHours() + fromNowHourCorrection

                let remainder = startMinute % step;
                startMinute = remainder === 0 ? step : startMinute + step - remainder;

                if ( (startMinute) > 59 ) {
                    startHour++
                    startMinute = 0
                }


            }

            if ( startHour > 23) {
                return []
            }

            for (let i = startHour; i < 24; i++) {
                for (let j = 0; j < steps; j++) {
                    let minutes = j * step;
                    if (i * 60 + minutes >= startHour * 60 + startMinute) {
                        let time = `${String(i).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
                        items.push(
                            { text: time, value: time },
                        )
                    }
                }
            }

            return items
        },

    }
}